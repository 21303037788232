// NotFoundPage 페이지
const NotFoundPage = () => {
  return (
    <div>
      <h1>NotFound 페이지입니다!</h1>
    </div>
  );
};

export default NotFoundPage;
